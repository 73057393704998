import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellComponent, ColumnDefinition } from "tabulator-tables";
import { Instruments } from "../../../../../../../api/compute/Instruments";
import {
  reactFormatter,
  TableEventsV2,
  TableV2,
} from "../../../../../../../components/table/v2/TableCoreV2";
import { TrendratingTableV2 } from "../../../../../../../components/table/v2/TableV2";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import Search from "../../../../../widgets/ReactSearch/Search";
import { getTotalWeights } from "./utils";

type Props = {
  type: "PORTFOLIO" | "BASKET";
  positions: any[];
  updateTime: string | null;
  addHoldingHandler: Function;
  isLoading: boolean;
  updateHoldingHandler: Function;
  removeHoldingHandler: Function;
  listenerEqualize: Function;
  listenerNormalize: Function;
};

export default function TableSection({
  type,
  positions,
  updateTime,
  addHoldingHandler,
  updateHoldingHandler,
  isLoading,
  removeHoldingHandler,
  listenerEqualize,
  listenerNormalize,
}: Props) {
  const environment = useEnvironment();
  const instrumentsAPI = useMemo(() => {
    return new Instruments(environment.get("setup"));
  }, [environment]);

  //#region - FORMATERS
  const formatter = useFormatter();
  const priceFormatter = useCallback(
    (cell: CellComponent) => {
      const data = cell.getData();
      return (
        formatter.custom("price", {
          options: {
            isPercentage: false,
            notAvailable: {
              input: null,
              output: "",
            },
          },
          output: "HTML",
          value: data["vc"],
          valueHelper: {
            currency: data["currency"],
          },
        }) ?? ""
      );
    },
    [formatter]
  );
  const weightFormatter = useCallback(
    (cell: CellComponent) => {
      const data = cell.getData();
      return (
        formatter.custom("number", {
          options: {
            isPercentage: true,
            notAvailable: {
              input: null,
              output: "",
            },
          },
          output: "HTML",
          value: data["weight"],
        }) ?? ""
      );
    },
    [formatter]
  );
  const ratingFormatter = useCallback(
    (cell: CellComponent) => {
      const data = cell.getData();
      const rate = data["rc"];
      if (rate) {
        return (
          formatter.custom("rating", {
            options: {
              notAvailable: {
                input: 0,
                output: "-",
              },
            },
            output: "HTML",
            value: rate,
            valueHelper: {
              rateChange: data["lr"],
              rateDate: data["dr"],
              ratePrev: data["rrr"],
            },
          }) ?? ""
        );
      }
      return "-";
    },
    [formatter]
  );
  //#endregion

  const onEditWeights = useCallback(
    (inputValue, cell) => {
      cell.setValue(`${inputValue / 100}`);
      weightFormatter(cell);
      let _value = cell.getRow().getData();
      updateHoldingHandler({
        weight: parseFloat(_value.weight),
        symbol: _value.symbol,
      });
    },
    [updateHoldingHandler, weightFormatter]
  );

  //#region - COLUMNS AND ROWS
  const columns = useMemo<ColumnDefinition[]>(() => {
    let cols: ColumnDefinition[] = [];
    if (type === "PORTFOLIO") {
      cols.push({
        title: "Weights",
        field: "weight",
        widthGrow: 2,
        sorter: "number",
        formatter: reactFormatter(
          <EditWeightsWidget
            onChangeWeights={onEditWeights}
            weightFormatter={weightFormatter}
          />
        ),
      });
    }
    const _cols = [
      {
        title: "Ticker",
        field: "ticker",
        widthGrow: 1,
        sorter: "string",
      },
      {
        title: "Name",
        field: "name",
        widthGrow: 4,
        sorter: "string",
      },
      {
        title: "Price",
        field: "vc",
        formatter: priceFormatter,
        widthGrow: 2,
        sorter: "number",
      },
      {
        title: "Rating",
        field: "rc",
        formatter: ratingFormatter,
        widthGrow: 1,
        sorter:"number"
      },
      {
        title: "Market",
        field: "_s_where",
        widthGrow: 2,
        sorter: "string",
      },
      {
        title: "",
        field: "",
        formatter: reactFormatter(
          <RemoveRowBtn removeHoldingHandler={removeHoldingHandler} />
        ),
      },
    ];
    cols = [...cols, ..._cols] as any;
    return cols;
  }, [
    onEditWeights,
    priceFormatter,
    ratingFormatter,
    removeHoldingHandler,
    type,
    weightFormatter,
  ]);
  const [rowData, setRowData] = useState([]);
  //#endregion

  useEffect(() => {
    if (positions.length > 0) {
      const symbols = positions.map((item) => item.symbol);
      const properties: any = {
        properties: [
          {
            date: null,
            property: "type",
          },
          {
            date: null,
            property: "vc",
          },
          {
            date: null,
            property: "rc",
          },
          {
            date: null,
            property: "name",
          },
          {
            date: null,
            property: "ticker",
          },
          {
            date: null,
            property: "country",
          },
        ],
        symbols: symbols,
        type: "security",
      };
      instrumentsAPI.fetch(properties).then((response) => {
        let _response = response.data;

        for (let i = 0; i < _response.length; i++) {
          for (let j = 0; j < positions.length; j++) {
            if (positions[j].symbol === _response[i].symbol) {
              _response[i]["weight"] = positions[j].weight;
            }
          }
        }

        var market: any = null;
        var position: any = null;
        const txField = environment.get("setup")["taxonomyFields"];
        const taxonomies = environment.get("setup")["taxonomies"];
        var taxonomyWhere = taxonomies[txField["security"]["country"]];
        for (let i = 0, length = _response.length; i < length; i++) {
          position = _response[i];
          if (position.type === "ETF") {
            taxonomyWhere = taxonomies[txField["ETF"]["etfgeo"]];
          }

          market = taxonomyWhere[position["country"]];
          position["_s_where"] = market["name"];

          if (position["weight"] == null) {
            position["weight"] = 0;
          }
        }

        setRowData(_response);
      });
    }
    setRowData([]);
  }, [environment, instrumentsAPI, positions]);

  //#region - TrendratingTableV2 SETUP AND HANDLERS
  const tableRef = useRef<{ getInstance: () => TableV2 }>();
  const [tableReady, setTableReady] = useState(false);
  const [columnsLoaded, setColumnsLoaded] = useState(false);

  useEffect(() => {
    if (tableReady && columnsLoaded) {
      const table = tableRef?.current?.getInstance();
      table?.insertData(rowData ?? []);
    }
  }, [columnsLoaded, rowData, tableReady]);
  useEffect(() => {
    if (tableReady) {
      const table = tableRef?.current?.getInstance();
      table?.insertColumns(columns);
    }
  }, [columns, tableReady]);
  const tableOptions = useMemo(() => {
    return {
      tableOption: {
        ajaxSorting: false,
      },
    };
  }, []);
  const tableEvents: TableEventsV2 = useMemo(
    () => ({
      onTableBuilt: () => {
        setTableReady(true);
      },
      onTableDestroyed: () => {
        setTableReady(false);
      },
      columnsLoaded: (columns) => {
        if (columns.length) {
          setColumnsLoaded(true);
        }
      },
    }),
    []
  );
  //#endregion

  return (
    <Card
      sx={{ boxShadow: 3, height: "100%", maxHeight: "100%", width: "50%" }}
    >
      <CardContent
        sx={{
          overflow: "hidden",
          height: "100%",
          minHeight: 0,
          display: "flex",
          gap: 1,
          flexDirection: "column",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Search
            disabled={isLoading}
            cleanOnSelect={true}
            hasRecents={true}
            showInstrumentInfoOnSelect={false}
            placeHolder="Add new holding"
            onSelectInstrument={addHoldingHandler}
          />
          {type === "PORTFOLIO" && (
            <Box display={"flex"} gap={2} flex={1} justifyContent={"flex-end"}>
              <Button
                disabled={isLoading}
                size="small"
                onClick={() => listenerNormalize()}
              >
                <Typography>100%</Typography>
              </Button>
              <Button
                onClick={() => listenerEqualize()}
                disabled={isLoading}
                size="small"
              >
                <Typography>Equal weighted</Typography>
              </Button>
            </Box>
          )}
        </Box>
        {type === "PORTFOLIO" && !isLoading && (
          <Box>
            <Typography>
              Double click on column <strong>"weights"</strong> to edit the
              weight percentage
            </Typography>
          </Box>
        )}
        {isLoading && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"100%"}
            flexDirection={"column"}
            gap={2}
            minHeight={0}
          >
            <Typography>Loading data...</Typography>
            <CircularProgress />
          </Box>
        )}
        {/* {!isLoading && ( */}
        <Box display={"flex"} width={"100%"} height={"100%"} minHeight={0}>
          <TrendratingTableV2
            ref={tableRef}
            tableEvents={tableEvents}
            tableOptions={tableOptions}
          />
        </Box>
        {/* )} */}
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} gap={2}>
            {type === "PORTFOLIO" && (
              <Chip
                label={
                  <Typography>
                    Total weight:{" "}
                    <strong>
                      {positions ? getTotalWeights(positions).toFixed(2) : 0}%
                    </strong>
                  </Typography>
                }
              />
            )}
            <Chip
              label={
                <Typography>
                  Number of positions: <strong>{positions?.length}</strong>
                </Typography>
              }
            />
          </Box>
          {updateTime && (
            <Box>
              <Chip
                label={
                  <Typography>
                    <span
                      dangerouslySetInnerHTML={{ __html: updateTime }}
                    ></span>
                  </Typography>
                }
              />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

type EditWeightsWidgetProps = {
  onChangeWeights: Function;
  weightFormatter: Function;
  cell?: any;
};
const EditWeightsWidget = ({
  cell,
  onChangeWeights,
  weightFormatter,
}: EditWeightsWidgetProps) => {
  const initValue = useMemo(() => {
    let _value = cell.getValue();
    return _value * 100;
  }, [cell]);

  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(initValue);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onKeyDownListener = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" || e.key === "Escape") {
        e.preventDefault();
        onChangeWeights(inputValue, cell);
        setInputValue(initValue); // Ripristino iniziale
        setIsEditing(false);
      }
    },
    [cell, inputValue, onChangeWeights, initValue]
  );

  const onBlurListener = useCallback(() => {
    // Il setTimeout ritarda le modifiche al DOM fino a quando l'evento `blur` è completamente gestito.
    // Questo previene errori che si verificano quando il nodo viene rimosso o modificato
    // durante l'elaborazione dell'evento `blur`.
    setTimeout(() => {
      if (inputRef.current) {
        onChangeWeights(inputValue, cell);
        setInputValue(initValue); // Ripristino iniziale
        setIsEditing(false);
      }
    }, 0);
  }, [cell, inputValue, onChangeWeights, initValue]);

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let _value = e.target.value;
      setInputValue(_value as any);
    },
    []
  );

  const onDoubleClickOnSpan = useCallback(
    (e) => {
      if (!isEditing) {
        e.stopPropagation();
        setIsEditing(true);
      }
    },
    [isEditing]
  );

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.select();
    }
  }, [isEditing]);

  return isEditing ? (
    <input
      ref={inputRef}
      step={0.01}
      autoFocus={true}
      value={inputValue}
      onChange={onChangeInput}
      onKeyDown={onKeyDownListener}
      onBlur={onBlurListener}
      onDoubleClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      type="number"
      style={{ border: "solid 1px black", width: "100%", height: "100%" }}
    />
  ) : (
    <span
      style={{ display: "block", width: "100%", height: "100%" }}
      onDoubleClick={onDoubleClickOnSpan}
    >
      {weightFormatter(cell)}
    </span>
  );
};

type RemoveRowBtnProps = {
  removeHoldingHandler: Function;
  cell?: any;
};
const RemoveRowBtn = ({ cell, removeHoldingHandler }: RemoveRowBtnProps) => {
  return (
    <span
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <DeleteIcon
        color="action"
        onClick={() => {
          let data: any = cell.getData();
          removeHoldingHandler(data.symbol);
        }}
        sx={{ fontSize: "0.9vw" }}
      />
    </span>
  );
};
